import React from "react";
import "./App.css";



function Resume() {
  return (
    <div id="Resume">
      <h1 className="Resume">Resume</h1>
      <div class="Bar"></div>
      <div className="Resume-link">
        <a href='mailto:manamiueda1@gmail.com' target="_blank" className="Try-demo" type="button">
          Email Me
        </a>

      </div>
    </div>
  );
}

export default Resume;

